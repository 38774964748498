import firebase from "firebase/app";
import "firebase/firestore";

const firebaseProdConfig = {
  apiKey: "AIzaSyD2YAiipBLNYg058Wm-sPE-e2dPDn_zX8w",
  authDomain: "typebot.firebaseapp.com",
  databaseURL: "https://typebot.firebaseio.com",
  projectId: "typebot",
  storageBucket: "typebot.appspot.com",
  messagingSenderId: "129364780042",
  appId: "1:129364780042:web:209c0b4fc488269d84ebf3",
};

const firebaseDevConfig = {
  apiKey: "AIzaSyCECGjrN5Ke1Maa9PUfNcPVvBJsjOhumSg",
  authDomain: "typebot-dev.firebaseapp.com",
  databaseURL: "https://typebot-dev.firebaseio.com",
  projectId: "typebot-dev",
  storageBucket: "typebot-dev.appspot.com",
  messagingSenderId: "601673535995",
  appId: "1:601673535995:web:968894a5c3d89d88147b5e",
  measurementId: "G-9CY3JP2RWG",
};

export let db: firebase.firestore.Firestore;

export const initFirebase = () => {
  if (firebase.apps.length > 0) return;
  firebase.initializeApp(
    process.env.NODE_ENV === "production"
      ? firebaseProdConfig
      : firebaseDevConfig
  );
  db = firebase.firestore();
  let firestoreSettings: firebase.firestore.Settings | undefined;
  if (typeof window !== "undefined") {
    if (window.location.hostname.includes("localhost")) {
      firestoreSettings = {
        host: "localhost:8080",
        ssl: false,
        ignoreUndefinedProperties: true,
        experimentalAutoDetectLongPolling: true,
        merge: true,
      };
    } else if (
      window.location.hostname.startsWith("192") ||
      window.location.hostname.startsWith("172")
    ) {
      firestoreSettings = {
        host: `${window.location.hostname}:8080`,
        ssl: false,
        ignoreUndefinedProperties: true,
        experimentalAutoDetectLongPolling: true,
        merge: true,
      };
    } else {
      firestoreSettings = {
        ignoreUndefinedProperties: true,
        experimentalAutoDetectLongPolling: true,
        merge: true,
      };
    }
  }
  db.settings(firestoreSettings ?? { merge: true });
};
