import React from "react";
import "../assets/styles/spinningLoader.css";
import "@typebot-io/engine/lib/styles.css";
import { initFirebase } from "../lib/firebase/clientApp";

type Props = {
  Component: React.ComponentType;
  pageProps: {
    [key: string]: unknown;
  };
};

initFirebase();

export default function MyApp({ Component, pageProps }: Props): JSX.Element {
  const { ...componentProps } = pageProps;

  return <Component {...componentProps} />;
}
