// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
let dsn =
  SENTRY_DSN ||
  "https://3f9ebaa4a10d4692a92508bb8405f0ae@o568702.ingest.sentry.io/5714600";
if (process.env.NODE_ENV !== "production") {
  dsn = undefined;
}
Sentry.init({
  dsn,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
